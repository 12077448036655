import httpClient from 'shared/utils/http-client'
import {
  MailingSettingsCreateDomainInterface,
  MailingSettingsDomainInterface,
} from 'modules/profile/mailing-settings/types/mailing-settings-domain-interface'

export const MAILING_DNS_SETTINGS_API = '/api/dashboard/customer/settings/mailing/dns-settings'

export const createDomain = (data: MailingSettingsCreateDomainInterface) =>
  httpClient.put<MailingSettingsDomainInterface>(MAILING_DNS_SETTINGS_API, data)

export const removeDomain = (id: number) => httpClient.delete(`${MAILING_DNS_SETTINGS_API}/${id}`)
