import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import { EMAIL_STATISTICS_API } from '../../../mailing/email-statistics/api/email-statistics-api'
import {
  RemovalConditionsInterface,
  RemovalConditionsUpdateInterface,
} from '../types/removal-conditions-interface'

export function useRemovalConditionsUpdate() {
  const { fetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.put,
    RemovalConditionsInterface[]
  >({
    method: RequestMethodsEnum.put,
    showFieldsErrorToast: true,
  })

  return {
    removalConditionsUpdate: (
      newData: RemovalConditionsUpdateInterface,
    ): Promise<RemovalConditionsInterface[]> =>
      fetcher(`${EMAIL_STATISTICS_API}/contact/removal-conditions`, newData),
  }
}
