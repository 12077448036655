import React from 'react'
import ConfirmationModal from 'shared/components/confirmation-modal'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'

interface ResendSenderEmailAddressModalProps {
  onResend: () => Promise<void>
  opened: boolean
  onClose: () => void
}

function ResendSenderEmailAddressModal({
  onResend,
  opened,
  onClose,
}: ResendSenderEmailAddressModalProps) {
  const { t } = useLocoTranslation()

  const handleConfirm = async () => {
    await onResend()
  }

  return (
    <ConfirmationModal
      opened={opened}
      onClose={onClose}
      onConfirm={handleConfirm}
      confirmationText={t('dashboard.settings.mailing.sender_email.resend_modal.title')}
      title={t('confirmation_modal.default_title')}
    />
  )
}

export default ResendSenderEmailAddressModal
