import { MailingSettingsEditInterface } from '../types/mailing-settings-interface'
import { getEmptyLocaleValues } from 'shared/utils/get-empty-locale-values'

export const defaultValues: MailingSettingsEditInterface = {
  affiliateEmailLink: true,
  emailMessageFooter: '',
  sendTestEmailAfterNewsletterEditing: true,
  senderEmailAddress: '',
  senderEmailName: '',
  sendgridApiKey: null,
  testEmailAddress: 'dmitriypetrovetss@yandex.ru',
  displayUnsubscribeFromCampaignLink: false,
  unsubscriptionLinkText: getEmptyLocaleValues(),
}
