import { Float } from '@headlessui-float/react'
import { Listbox } from '@headlessui/react'
import React from 'react'
import BaseList from 'shared/components/form-select/base-list'
import { ExtendedDataType, FormSelectProps } from 'shared/components/form-select/index'
import VirtualizedList from 'shared/components/form-select/virtualized-list'
import { Loader } from 'shared/components/loader'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import ArrowDown from 'shared/icons/arrow-down-icon'
import CloseIcon from 'shared/icons/close-icon'

interface FormSelectInlineInputProps<T extends ExtendedDataType> extends FormSelectProps<T> {}

const FormSelectInline = <T extends ExtendedDataType>({
  data,
  value,
  onChange,
  disabled,
  isPreFetching,
  withoutVirtualization,
  optionIconRenderer,
  getOptionDataAttribute,
  groups,
  withoutCloseIcon,
  className,
  labelClassName,
}: FormSelectInlineInputProps<T>) => {
  const { t } = useLocoTranslation()
  const item = data?.find(item => item.id === value) || null
  const isVirtualizedList = (data || []).length > 20 && !withoutVirtualization
  return (
    <Listbox value={item || null} onChange={data => data && onChange(data.id)}>
      {({ open }) => (
        <Float offset={4} portal flip>
          <Listbox.Button
            className={`relative flex items-center gap-0.5 w-fit cursor-pointer text-left truncate ${className}`}
          >
            <span className={`underline decoration-dashed truncate ${labelClassName}`}>
              {item?.caption || t('global.select')}
            </span>
            {value !== undefined && !withoutCloseIcon && (
              <div
                onClick={e => {
                  if (disabled || isPreFetching) return
                  e.stopPropagation()
                  onChange(undefined)
                }}
                className={`flex items-center`}
              >
                <CloseIcon
                  className={`${
                    disabled || isPreFetching
                      ? 'cursor-default fill-transparent'
                      : 'cursor-pointer fill-darkblue'
                  }`}
                />
              </div>
            )}
            <div className="flex items-center">
              <ArrowDown
                className={`${
                  disabled || isPreFetching
                    ? 'cursor-default stroke-transparent'
                    : 'cursor-pointer stroke-darkblue'
                } transition-transform duration-300 ${open && 'rotate-180'}`}
                aria-hidden="true"
              />
            </div>
          </Listbox.Button>
          <Listbox.Options className={`relative focus-visible:outline-none`}>
            <div className={'bg-white rounded-lg'}>
              {data === undefined ? (
                <div className="flex bg-white justify-center px-4 py-1 border rounded-md border-gray/30">
                  <Loader className="scale-75" />
                </div>
              ) : isVirtualizedList ? (
                <VirtualizedList
                  data={data}
                  value={value}
                  getOptionDataAttribute={getOptionDataAttribute}
                  optionIconRenderer={optionIconRenderer}
                  as={Listbox.Option}
                />
              ) : (
                <BaseList
                  data={data}
                  value={value}
                  getOptionDataAttribute={getOptionDataAttribute}
                  groups={groups}
                  optionIconRenderer={optionIconRenderer}
                  as={Listbox.Option}
                />
              )}
            </div>
          </Listbox.Options>
        </Float>
      )}
    </Listbox>
  )
}

export default FormSelectInline
