import { Editor } from '@ckeditor/ckeditor5-core'
import { CKEditor as ReactCKEditor } from '@ckeditor/ckeditor5-react'
import { MailingId } from 'modules/mailing/types/mailing-interface'
import { useEffect, useRef, useState } from 'react'
import ConfirmationModal from 'shared/components/confirmation-modal'
import { UnsavedEmailsEnum } from 'shared/components/dashboard-mail-editor/enums/unsaved-emails-enum'
import { getBodyContent } from 'shared/components/dashboard-mail-editor/utils/get-styled-body'
import database from 'shared/db'
import { EmailsInterface } from 'shared/db/tables/emails'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'

interface UseUnsavedEmailInterface {
  mailingId?: MailingId
  initialBody?: string
  currentBody?: string
  type?: UnsavedEmailsEnum
}

export type SetCkeditorRefType = (ref: ReactCKEditor<Editor>) => void

export const useUnsavedEmail = ({
  mailingId,
  initialBody,
  currentBody,
  type,
}: UseUnsavedEmailInterface) => {
  const ckeditor = useRef<ReactCKEditor<Editor> | null>(null)
  const [opened, setOpened] = useState(false)
  const [needToShowModal, setNeedToShowModal] = useState(true)
  const timerRef = useRef<NodeJS.Timeout | null>(null)
  const { t } = useLocoTranslation()

  const initial = initialBody && getBodyContent(initialBody)
  const current = currentBody && getBodyContent(currentBody)

  const [tempBody, setTempBody] = useState<string>()

  useEffect(() => {
    if (initial !== undefined) {
      setTempBody(initial)
    }
  }, [initial])

  useEffect(() => {
    if (!needToShowModal || (!mailingId && !type)) return
    const checkDbRecord = async () => {
      const existingRecord = mailingId
        ? await database.emails.where('mailing_id').equals(mailingId).first()
        : type
        ? await database.emails.where('type').equals(type).first()
        : undefined
      if (existingRecord && existingRecord.body !== current) {
        setTempBody(existingRecord.body)
        setOpened(true)
      }
      setNeedToShowModal(false)
    }
    checkDbRecord()
  }, [current, mailingId, needToShowModal, type])

  useEffect(() => {
    if (initial === undefined || !current || tempBody === undefined) return
    if (opened || current === tempBody || (!mailingId && !type)) return
    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }
    timerRef.current = setTimeout(async () => {
      const existingRecord = mailingId
        ? await database.emails.where('mailing_id').equals(mailingId).first()
        : type
        ? await database.emails.where('type').equals(type).first()
        : undefined
      const addRecord = async () => {
        if (existingRecord) {
          existingRecord.body = current
          await database.emails.put(existingRecord)
        } else {
          const newRecord: EmailsInterface = {
            mailing_id: mailingId || null,
            type: type || null,
            body: current,
          }
          await database.emails.add(newRecord)
        }
        setTempBody(current)
      }
      try {
        await addRecord()
      } catch {
        // No space in indexedDB
        const firstRecord = database.emails.offset(0)
        if (firstRecord) {
          await firstRecord.delete()
          await addRecord()
        }
      }
    }, 3000)
    return () => clearTimeout(timerRef.current || 0)
  }, [current, initial, mailingId, opened, tempBody, type])

  const pasteUnsavedData = () => {
    if (!tempBody) return
    ckeditor.current?.editor?.data.set(tempBody)
  }

  const setCkeditorRef = (ref: ReactCKEditor<Editor>) => {
    ckeditor.current = ref
  }

  const removeDbRecord = async () => {
    if (mailingId) {
      const existingRecord = database.emails.where('mailing_id').equals(mailingId)
      if (existingRecord) {
        await existingRecord.delete()
        setTempBody(current)
      }
    } else if (type) {
      const existingRecord = database.emails.where('type').equals(type)
      if (existingRecord) {
        await existingRecord.delete()
        setTempBody(current)
      }
    }
  }
  const CkeditorUnsavedEmail = (
    <ConfirmationModal
      confirmationText={t('dashboard.email.confirm_unsaved_email')}
      opened={opened}
      onDecline={async () => {
        await removeDbRecord()
      }}
      onClose={() => setOpened(false)}
      onConfirm={pasteUnsavedData}
    />
  )

  return { CkeditorUnsavedEmail, setCkeditorRef, removeDbRecord, setNeedToShowModal }
}
