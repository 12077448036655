import React from 'react'

function CheckCheckboxIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="13"
      height="10"
      viewBox="0 0 13 10"
      xmlns="http://www.w3.org/2000/svg"
      fill="white"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.39016 9.4496L0.189285 5.24872C-0.063095 4.99635 -0.063095 4.58713 0.189285 4.33472L1.10326 3.42073C1.35564 3.16832 1.76487 3.16832 2.01725 3.42073L4.84717 6.25063L10.9085 0.189285C11.1609 -0.063095 11.5701 -0.063095 11.8225 0.189285L12.7365 1.10327C12.9889 1.35565 12.9889 1.76487 12.7365 2.01727L5.30415 9.44963C5.05174 9.70201 4.64254 9.70201 4.39016 9.4496Z"
      />
    </svg>
  )
}

export default CheckCheckboxIcon
