import DnsSettingsModal from 'modules/profile/mailing-settings/components/dns-settings-modal'
import { MailingSettingsDomainInterface } from 'modules/profile/mailing-settings/types/mailing-settings-domain-interface'
import React, { useState } from 'react'
import EyeIcon from 'shared/icons/eye-icon'

function DnsSettingsModalWithButton({
  domainData,
}: {
  domainData: MailingSettingsDomainInterface
}) {
  const [opened, setOpened] = useState(false)

  const onClose = () => {
    setOpened(false)
  }

  return (
    <>
      <div className="lg:ml-8 cursor-pointer">
        <EyeIcon onClick={() => setOpened(true)} className="hover:fill-blue" />
      </div>
      <DnsSettingsModal
        domainId={domainData.id}
        dnsSettings={domainData.dnsSettings}
        opened={opened}
        onClose={onClose}
      />
    </>
  )
}

export default DnsSettingsModalWithButton
