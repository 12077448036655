import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import useUser from 'shared/hooks/use-user'
import useSWR from 'swr'
import { EMAIL_STATISTICS_API } from '../../../mailing/email-statistics/api/email-statistics-api'
import { RemovalConditionsInterface } from '../types/removal-conditions-interface'

export function useRemovalConditions() {
  const { user, isWorkspaceOwner, isWsAdmin } = useUser()
  const canManageRemovalConditions = isWorkspaceOwner || isWsAdmin

  const { fetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.get,
    RemovalConditionsInterface[]
  >({ method: RequestMethodsEnum.get })

  const { data: removalConditions, mutate } = useSWR(() => {
    if (user && canManageRemovalConditions) {
      return `${EMAIL_STATISTICS_API}/contact/removal-conditions/list`
    }
    return null
  }, fetcher)

  return { removalConditions, mutate, canManageRemovalConditions }
}
