import {
  MAILING_DOUBLE_OPT_IN_SETTINGS_API,
  MAILING_DOUBLE_OPT_IN_SETTINGS_ATTACHMENTS_API,
} from 'modules/profile/mailing-settings/api/mailing-settings-double-opt-in-api'
import { MailingDoubleOptInSettingsInterface } from 'modules/profile/mailing-settings/types/mailing-settings-double-optin-interface'
import { AttachmentFileApiInterface } from 'shared/components/dashboard-mail-editor/types/email-interface'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import useUser from 'shared/hooks/use-user'
import useSWR from 'swr'

interface useMailingDoubleOptInInterface {
  shouldFetch: boolean
}

export function useMailingDoubleOptIn({ shouldFetch }: useMailingDoubleOptInInterface) {
  const { user } = useUser()
  const { fetcher } = useApiWrapperWithErrorValidation<RequestMethodsEnum.get, any>({
    method: RequestMethodsEnum.get,
  })

  const { data: mailingDoubleOptInSettings, mutate: mutateOptInSettings } =
    useSWR<MailingDoubleOptInSettingsInterface>(() => {
      if (user && shouldFetch) {
        return MAILING_DOUBLE_OPT_IN_SETTINGS_API
      }
      return null
    }, fetcher)

  const { data: mailingDoubleOptInAttachments, mutate: mutateOptInAttachments } =
    useSWR<AttachmentFileApiInterface>(() => {
      if (user && shouldFetch) {
        return MAILING_DOUBLE_OPT_IN_SETTINGS_ATTACHMENTS_API
      }
      return null
    }, fetcher)

  return {
    mailingDoubleOptInSettings,
    mailingDoubleOptInAttachments,
    mutateOptInSettings,
    mutateOptInAttachments,
  }
}
