import { DomainActiveRootStatusEnum } from 'modules/profile/mailing-settings/components/domain-verification/enums/domain-active-root-status-enum'
import { useDomainVerification } from 'modules/profile/mailing-settings/components/domain-verification/hooks/use-domain-verification'
import { getHelpPage } from 'modules/profile/mailing-settings/components/domain-verification/utils/get-help-page'
import { DomainId } from 'modules/profile/mailing-settings/types/mailing-settings-domain-interface'
import { FC } from 'react'
import { Loader } from 'shared/components/loader'
import TransLoco from 'shared/components/trans-loco'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import useUser from 'shared/hooks/use-user'

interface DomainVerificationProps {
  domainId: DomainId
}

const DomainVerification: FC<DomainVerificationProps> = ({ domainId }) => {
  const { user } = useUser()
  const { t } = useLocoTranslation()
  const { status } = useDomainVerification(domainId)

  const getActiveRootStatus = () => {
    switch (status) {
      case DomainActiveRootStatusEnum.verified:
        return (
          <span className={'text-green'}>
            {t('dashboard.settings.mailing.verification.success')}
          </span>
        )
      case DomainActiveRootStatusEnum.missing:
        return (
          <span className={'text-danger'}>
            <TransLoco
              t={t}
              i18nKey={'dashboard.settings.mailing.verification.fail'}
              components={{
                a: <a className={'primary-link'} href={getHelpPage(user?.dashboardLocale)} />,
              }}
            />
          </span>
        )
      case null:
        return null
      default:
        return (
          <div className={'flex flex-col items-center gap-1'}>
            <Loader />
            <span>{t('dashboard.settings.mailing.verification.pending')}</span>
          </div>
        )
    }
  }

  return <div className={'flex justify-center'}>{getActiveRootStatus()}</div>
}

export default DomainVerification
