import { useEffect, useState } from 'react'

function useScrollToElement(
  selector?: string,
  timeout = process.env.NODE_ENV === 'development' ? 20_000 : 5_000,
  interval = 100,
) {
  const [isElementLoaded, setIsElementLoaded] = useState(false)

  useEffect(() => {
    if (!selector) return

    const startTime = Date.now()

    const checkElement = setInterval(() => {
      const elapsed = Date.now() - startTime
      if (elapsed >= timeout) {
        clearInterval(checkElement)
        console.warn('Timeout: Element not found.')
        return
      }

      const target = document.querySelector(selector)
      if (target) {
        target.scrollIntoView({ behavior: 'smooth' })
        setIsElementLoaded(true)
        clearInterval(checkElement)
      }
    }, interval)

    return () => clearInterval(checkElement)
  }, [selector, timeout, interval])

  return isElementLoaded
}

export default useScrollToElement
