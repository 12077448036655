import React from 'react'
import {
  FieldErrorAndDescription,
  FieldErrorAndDescriptionProps,
} from './form/field-error-and-description'
import { FieldLabel, FieldLabelProps } from './form/field-label'

type TextareaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement> &
  FieldErrorAndDescriptionProps &
  FieldLabelProps & {
    isLoading?: boolean
  }

function FormTextarea({
  label,
  labelClassName,
  value,
  rows,
  className,
  onChange,
  onFocus,
  maxLength,
  error,
  placeholder,
  required,
  description,
  isLoading,
  ...rest
}: TextareaProps) {
  return (
    <label className="text-sm font-medium flex flex-col gap-1">
      <FieldLabel label={label} required={required} labelClassName={labelClassName} />
      <textarea
        className={`w-full text-darkblue main-transition-colors rounded-lg border ${
          error ? 'border-danger' : 'border-gray/30'
        } py-2.5 px-4 focus:border-blue focus:outline-none placeholder:text-gray-300/70 ${
          className || ''
        } resize-none ${
          isLoading
            ? 'animate-pulse bg-gray-600/40 text-transparent disabled:bg-gray-600/40'
            : 'disabled:bg-gray-200/40'
        }`}
        value={value}
        rows={rows || 5}
        onChange={onChange}
        onFocus={onFocus}
        maxLength={maxLength}
        placeholder={placeholder}
        spellCheck={false}
        disabled={rest.disabled}
      />
      <FieldErrorAndDescription description={description} error={error} />
    </label>
  )
}

export default FormTextarea
