import React from 'react'

function CopyIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="#CBD5E1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.8579 16.6084H14.4205V7.80825L10.3589 3.80801H4.67102V1.40806C4.67102 0.967118 5.03578 0.608398 5.48413 0.608398H13.6084L17.671 4.60864V15.8078C17.671 16.2492 17.3063 16.6084 16.8579 16.6084L16.8579 16.6084Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5131 20.6088H1.46049C1.02448 20.6088 0.671021 20.2554 0.671021 19.8193V5.60881C0.671021 5.17279 1.02448 4.81934 1.46049 4.81934H9.35523L13.3026 8.7667V19.8193C13.3026 20.2554 12.9491 20.6088 12.5131 20.6088Z"
      />
    </svg>
  )
}

export default CopyIcon
