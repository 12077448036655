import React from 'react'

const MailingSettingsSkeleton = () => (
  <div className="flex flex-col w-full h-fit rounded-md overflow-x-hidden gap-5 bg-white">
    <div className="flex flex-col gap-7 lg:gap-10 p-5 animate-pulse ">
      <div className="flex flex-col lg:flex-row justify-between [&>*]:flex-1 lg:h-[44px] gap-7 lg:gap-10">
        <div className="bg-gray-600 rounded text-transparent my-2 py-1 lg:py-0">.</div>
      </div>
      <div className="flex flex-col lg:flex-row justify-between [&>*]:flex-1 lg:h-[104px] gap-7 lg:gap-10">
        <div className="bg-gray-600 rounded text-transparent my-2 py-1 lg:py-0">.</div>
      </div>
      <div className="flex flex-col lg:flex-row justify-between [&>*]:flex-1 lg:h-[44px] gap-7 lg:gap-10">
        <div className="bg-gray-600 rounded text-transparent my-2 py-1 lg:py-0">.</div>
      </div>
      <div className="flex flex-col lg:flex-row justify-between [&>*]:flex-1 lg:h-[66px] gap-7 lg:gap-10">
        <div className="bg-gray-600 rounded text-transparent my-3 py-2.5 lg:py-0">.</div>
        <div className="bg-gray-600 rounded text-transparent my-3 py-2.5 lg:py-0">.</div>
        <div className="bg-gray-600 rounded text-transparent my-3 py-2.5 lg:py-0">.</div>
      </div>
      <div className="flex flex-col lg:flex-row justify-between [&>*]:flex-1 lg:h-[44px] gap-7 lg:gap-10">
        <div className="bg-gray-600 rounded text-transparent my-2 py-1 lg:py-0">.</div>
      </div>
      <div className="flex flex-col lg:flex-row justify-between [&>*]:flex-1 lg:h-[104px] gap-7 lg:gap-10">
        <div className="bg-gray-600 rounded text-transparent my-2 py-1 lg:py-0">.</div>
      </div>
      <div className="flex flex-col lg:flex-row justify-between [&>*]:flex-1 lg:h-[44px] gap-7 lg:gap-10">
        <div className="bg-gray-600 rounded text-transparent my-2 py-1 lg:py-0">.</div>
      </div>
      <div className="flex flex-col lg:flex-row justify-between [&>*]:flex-1 lg:h-[44px] gap-7 lg:gap-10">
        <div className="bg-gray-600 rounded text-transparent my-2 py-1 lg:py-0">.</div>
      </div>
    </div>
  </div>
)

export default MailingSettingsSkeleton
