import { MAILING_SETTINGS_SENDER_EMAILS_API } from 'modules/profile/mailing-settings/api/mailing-settings-sender-emails-api'
import { SenderEmailsAddressesApiInterface } from 'modules/profile/mailing-settings/types/sender-email-address-interface'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import useUser from 'shared/hooks/use-user'
import { SwrPaginationInterface } from 'shared/types/pagination-interface'
import useSWR from 'swr'

export function useSenderEmailAddresses({ pagination, limit }: SwrPaginationInterface) {
  const { user } = useUser()
  const { fetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.get,
    SenderEmailsAddressesApiInterface
  >({ method: RequestMethodsEnum.get })

  const {
    data: emailAddresses,
    isValidating,
    mutate,
  } = useSWR(() => {
    if (!limit || !user) return
    if (pagination.startFromId === undefined) {
      return `${MAILING_SETTINGS_SENDER_EMAILS_API}/list?pagination[limit]=${limit}`
    } else if (pagination.startFromId) {
      return `${MAILING_SETTINGS_SENDER_EMAILS_API}/list?pagination[startFrom]=${pagination.startFromId}&pagination[order]=${pagination.order}&pagination[limit]=${limit}`
    }
    return null
  }, fetcher)

  return { emailAddresses, isValidating, mutate }
}
