import { MAILING_SETTINGS_API } from 'modules/profile/mailing-settings/api/mailing-settings-api'
import { MailingSettingsInterface } from 'modules/profile/mailing-settings/types/mailing-settings-interface'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import useUser from 'shared/hooks/use-user'
import useSWR from 'swr'

export function useMailingSettings() {
  const { user } = useUser()
  const { fetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.get,
    MailingSettingsInterface
  >({ method: RequestMethodsEnum.get })

  const { data: mailingData, mutate } = useSWR(() => {
    if (user) {
      return MAILING_SETTINGS_API
    }
    return null
  }, fetcher)

  return { mailingData, mutate }
}
