import { useEmailList } from 'modules/profile/mailing-settings/hooks/use-email-list'
import { EmailItemInterface } from 'modules/profile/mailing-settings/types/email-list-interface'
import { FC, useEffect, useState } from 'react'
import { CopyToClipboardButton } from 'shared/components/copy-to-clipboard-button'
import FormSelectInline from 'shared/components/form-select/form-select-inline'

interface DmarcProps {
  isReady: boolean
}

export const DMARC_KEY = '_dmarc'

const DMARC_STATIC = 'v=DMARC1; p=none; rua=mailto:'

const Dmarc: FC<DmarcProps> = ({ isReady }) => {
  const { data: emailList } = useEmailList(isReady)
  const [emailItem, setEmailItem] = useState<EmailItemInterface | undefined>()
  const selectData = emailList?.map(email => ({ id: email.id, caption: email.email }))
  const dmarc = DMARC_STATIC + emailItem?.email

  useEffect(() => {
    if (emailList && emailList.length > 0) {
      setEmailItem(emailList[0])
    }
  }, [emailList])
  return (
    <div className={'flex items-center w-full select-none'}>
      <span>{DMARC_STATIC}</span>
      <FormSelectInline
        value={emailItem?.id}
        onChange={data => {
          if (emailList) {
            const selectedEmail = emailList.find(email => email.id === data)
            setEmailItem(selectedEmail)
          }
        }}
        data={selectData}
        withoutCloseIcon
      />
      <CopyToClipboardButton
        className={'ml-1.5 [&_svg]:fill-blue/70'}
        text={dmarc}
        disabled={!emailItem}
      />
    </div>
  )
}

export default Dmarc
