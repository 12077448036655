import httpClient from 'shared/utils/http-client'
import {
  CreateSenderEmailAddressInterface,
  SenderEmailAddressId,
  SenderEmailAddressInterface,
} from 'modules/profile/mailing-settings/types/sender-email-address-interface'

export const MAILING_SETTINGS_SENDER_EMAILS_API = '/api/dashboard/customer/mailing/sender-email'

export const removeSenderEmailAddress = (id: SenderEmailAddressId) =>
  httpClient.delete(`${MAILING_SETTINGS_SENDER_EMAILS_API}/${id}`)

export const resendSenderEmailAddress = (id: SenderEmailAddressId) =>
  httpClient.post<SenderEmailAddressInterface>(`${MAILING_SETTINGS_SENDER_EMAILS_API}/${id}/resend`)

export const createSenderEmailAddress = (data: CreateSenderEmailAddressInterface) =>
  httpClient.post(MAILING_SETTINGS_SENDER_EMAILS_API, data)
