import { PropsWithChildren } from 'react'
import { twMerge } from 'tailwind-merge'

type SettingsSectionHeadingProps = PropsWithChildren & { className?: string }

function SettingsSectionHeading({ children, className }: SettingsSectionHeadingProps) {
  return (
    <h3
      className={twMerge(
        'flex grow justify-between whitespace-nowrap',
        'after:shrink after:w-full after:ml-6 after:border-gray-100 after:h-[0px] after:border-t-2 after:self-center after:content-[""] after:block',
        className,
      )}
    >
      {children}
    </h3>
  )
}

export default SettingsSectionHeading
