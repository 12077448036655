import { DateRangeEnum } from '../enums/date_range_enum'

export enum ActionRemovalConditionsEnum {
  unsubscribe = 'unsubscribe',
  delete = 'delete',
}

type RemovalConditionsType = 'email' | 'purchase'

export interface RemovalConditionsInterface {
  active: boolean
  type: RemovalConditionsType
  action: ActionRemovalConditionsEnum
  dateRange: DateRangeEnum
  lastCount: number
  lastExecutionDate: string | null
}

export interface RemovalConditionsUpdateInterface {
  listCleanings: Omit<RemovalConditionsInterface, 'lastCount' | 'lastExecutionDate'>[]
}
