import { MAILING_DNS_SETTINGS_API } from 'modules/profile/mailing-settings/api/mailing-settings-domain-api'
import { MailingSettingsDomainsApiInterface } from 'modules/profile/mailing-settings/types/mailing-settings-domain-interface'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import useUser from 'shared/hooks/use-user'
import { SwrPaginationInterface } from 'shared/types/pagination-interface'
import useSWR from 'swr'

interface useMailingDnsSettings extends SwrPaginationInterface {}

export function useMailingDnsSettings({ pagination }: useMailingDnsSettings) {
  const { user } = useUser()
  const { fetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.get,
    MailingSettingsDomainsApiInterface
  >({ method: RequestMethodsEnum.get })

  const { data: dnsMailingSettings, mutate } = useSWR(() => {
    if (user && pagination.startFromId === undefined) {
      return `${MAILING_DNS_SETTINGS_API}?pagination[limit]=10`
    } else if (user && pagination.startFromId) {
      return `${MAILING_DNS_SETTINGS_API}?pagination[startFrom]=${pagination.startFromId}&pagination[order]=${pagination.order}&pagination[limit]=10`
    }
    return null
  }, fetcher)

  return { dnsMailingSettings, mutate }
}
