import {
  updateMailingDoubleOptInSettings,
  uploadAttachments,
} from 'modules/profile/mailing-settings/api/mailing-settings-double-opt-in-api'
import { useMailingDoubleOptIn } from 'modules/profile/mailing-settings/hooks/use-mailing-double-optin'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { PrimaryButton } from 'shared/components/button'
import { SecondaryButton } from 'shared/components/button'
import { LightButton } from 'shared/components/button'
import DashboardMailEditor from 'shared/components/dashboard-mail-editor'
import { useUnsavedEmail } from 'shared/components/dashboard-mail-editor/hooks/use-unsaved-email'
import {
  AttachmentFileInterface,
  EmailBaseInterface,
  EmailErrorResponseContentInterface,
  EmailErrorType,
} from 'shared/components/dashboard-mail-editor/types/email-interface'
import { getStyledBody } from 'shared/components/dashboard-mail-editor/utils/get-styled-body'
import Modal, { ModalSizeEnum } from 'shared/components/modal'
import { BadRequest } from 'shared/errors/bad-request'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'

const defaultErrors: EmailErrorType = {
  body: '',
  fromEmail: '',
  fromName: '',
  subject: '',
  previewText: '',
  common: [],
}

function DoubleOptInModal() {
  const { t } = useLocoTranslation()
  const [errors, setErrors] = useState<EmailErrorType>(defaultErrors)
  const [isFetching, setIsFetching] = useState(false)
  const [opened, setOpened] = useState(false)
  const [tempState, setTempState] = useState<EmailBaseInterface>()
  const [attachmentsTempState, setAttachmentsTempState] = useState<
    (AttachmentFileInterface | File)[]
  >([])
  const [shouldFetchDoubleOptIn, setShouldFetchDoubleOptIn] = useState(false)
  const {
    mailingDoubleOptInSettings,
    mailingDoubleOptInAttachments,
    mutateOptInSettings,
    mutateOptInAttachments,
  } = useMailingDoubleOptIn({
    shouldFetch: shouldFetchDoubleOptIn,
  })
  useEffect(() => {
    if (mailingDoubleOptInAttachments && mailingDoubleOptInSettings) {
      setTempState(prev => {
        return {
          ...prev,
          ...mailingDoubleOptInSettings.currentEmail,
        }
      })
      setAttachmentsTempState(mailingDoubleOptInAttachments.attachments)
    }
  }, [mailingDoubleOptInAttachments, mailingDoubleOptInSettings])

  const handleConfirm = async () => {
    try {
      if (tempState) {
        const doubleOptInData = {
          currentEmail: {
            body: getStyledBody(tempState.body),
            fromEmail: tempState.fromEmail,
            fromName: tempState.fromName,
            subject: tempState.subject,
            previewText: tempState.previewText,
          },
        }
        setErrors(defaultErrors)
        setIsFetching(true)
        const [attachmentsResponse, settingsResponse] = await Promise.all([
          uploadAttachments(attachmentsTempState),
          updateMailingDoubleOptInSettings(doubleOptInData),
        ])
        await mutateOptInSettings(data => {
          if (data) {
            return { ...settingsResponse.data }
          }
        }, false)
        await mutateOptInAttachments(data => {
          if (data) {
            return { ...attachmentsResponse.data }
          }
        }, false)
        await removeDbRecord()
        onClose()
        toast.success(t('global.changes_saved'))
      }
    } catch (e) {
      setIsFetching(false)
      if (e instanceof BadRequest) {
        const mailingDoubleOptInSettingsErrors =
          e.errors as unknown as EmailErrorResponseContentInterface
        const fieldErrors = mailingDoubleOptInSettingsErrors.fields.currentEmail
        if (fieldErrors) {
          setErrors(prev => ({
            ...prev,
            body: fieldErrors.body?.join(''),
            fromEmail: fieldErrors.fromEmail?.join(''),
            fromName: fieldErrors.fromName?.join(''),
            subject: fieldErrors.subject?.join(''),
            previewText: fieldErrors.previewText?.join(''),
          }))
        }

        if (e.errors.common) {
          const commonErrors = e.errors.common
          setErrors(prev => {
            return {
              ...prev,
              common: commonErrors,
            }
          })
        }
      } else {
        toast.error(t('global.error'))
      }
    }
  }

  const onClose = () => {
    setOpened(false)
    setErrors(defaultErrors)
    setIsFetching(false)
  }

  const { CkeditorUnsavedEmail, setCkeditorRef, removeDbRecord, setNeedToShowModal } =
    useUnsavedEmail({
      mailingId: mailingDoubleOptInSettings?.id,
      initialBody: mailingDoubleOptInSettings?.currentEmail.body,
      currentBody: tempState?.body,
    })

  const onChangeEmail = (value: EmailBaseInterface) => setTempState(value)

  return (
    <>
      <LightButton
        className="bg-white border-solid border border-gray/30"
        onClick={() => {
          setOpened(true)
          setShouldFetchDoubleOptIn(true)
        }}
        isFetching={
          shouldFetchDoubleOptIn && (!mailingDoubleOptInSettings || !mailingDoubleOptInAttachments)
        }
      >
        {t('dashboard.settings.mailing.double_opt_in_edit_button_title')}
      </LightButton>
      <Modal
        opened={opened && (!!mailingDoubleOptInSettings || !!mailingDoubleOptInAttachments)}
        onClose={onClose}
        afterLeave={() => setShouldFetchDoubleOptIn(false)}
        afterEnter={() => setNeedToShowModal(true)}
        title={t('dashboard.settings.mailing.double_opt_in_edit_modal.title')}
        isFetching={isFetching}
        size={ModalSizeEnum.big}
      >
        <form className="flex flex-col gap-6 lg:gap-7">
          <DashboardMailEditor
            showSettingsLink={false}
            inModal
            isPreFetching={!mailingDoubleOptInSettings}
            tempState={tempState}
            initValue={mailingDoubleOptInSettings?.currentEmail.body}
            onChangeEmail={onChangeEmail}
            attachmentsTempState={attachmentsTempState}
            setAttachmentsTempState={setAttachmentsTempState}
            errors={errors}
            setCkeditorRef={setCkeditorRef}
            additionalSubstitutes={[
              {
                name: t(
                  'dashboard.settings.mailing.double_opt_in_edit_modal.available_replacements_confirmation_link_title',
                ),
                slug: 'double_opt_in_confirmation_link',
              },
            ]}
          />
          <div className="flex justify-center gap-4">
            <SecondaryButton
              width="large"
              onClick={e => {
                e.preventDefault()
                onClose()
              }}
              type="submit"
            >
              {t('confirmation_modal.cancel')}
            </SecondaryButton>
            <PrimaryButton
              width="large"
              onClick={async e => {
                e.preventDefault()
                await handleConfirm()
              }}
              isFetching={isFetching}
              disabled={isFetching}
            >
              {t('global.save')}
            </PrimaryButton>
          </div>
        </form>
        {CkeditorUnsavedEmail}
      </Modal>
    </>
  )
}

export default DoubleOptInModal
