import { MailingDoubleOptInSettingsInterface } from 'modules/profile/mailing-settings/types/mailing-settings-double-optin-interface'
import { isAttachmentFile } from 'modules/profile/mailing-settings/utils/file-utils'
import {
  AttachmentFileApiInterface,
  AttachmentFileInterface,
} from 'shared/components/dashboard-mail-editor/types/email-interface'
import httpClient from 'shared/utils/http-client'

export const MAILING_DOUBLE_OPT_IN_SETTINGS_API =
  '/api/dashboard/customer/settings/mailing/double-opt-in'

export const MAILING_DOUBLE_OPT_IN_SETTINGS_ATTACHMENTS_API =
  '/api/dashboard/customer/settings/mailing/double-opt-in/attachments'

export const updateMailingDoubleOptInSettings = (
  data: Pick<MailingDoubleOptInSettingsInterface, 'currentEmail'>,
) => httpClient.put<MailingDoubleOptInSettingsInterface>(MAILING_DOUBLE_OPT_IN_SETTINGS_API, data)

export function uploadAttachments(files: (AttachmentFileInterface | File)[]) {
  const formData: Record<string, any> = {}

  if (files.length > 0) {
    files.forEach((file, index) => {
      if (isAttachmentFile(file)) {
        formData[`attachments[${index}][id]`] = file.id
      } else {
        formData[`attachments[${index}][file][file][file]`] = file
      }
    })
  } else {
    return httpClient.post<AttachmentFileApiInterface>(
      MAILING_DOUBLE_OPT_IN_SETTINGS_ATTACHMENTS_API,
      {
        attachments: null,
      },
    )
  }

  return httpClient.postForm<AttachmentFileApiInterface>(
    MAILING_DOUBLE_OPT_IN_SETTINGS_ATTACHMENTS_API,
    formData,
  )
}
