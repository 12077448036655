import { DomainActiveRootStatusEnum } from 'modules/profile/mailing-settings/components/domain-verification/enums/domain-active-root-status-enum'
import { DomainVerificationInterface } from 'modules/profile/mailing-settings/components/domain-verification/types/domain-verification-interface'
import { DomainId } from 'modules/profile/mailing-settings/types/mailing-settings-domain-interface'
import { useEffect, useRef } from 'react'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import useUser from 'shared/hooks/use-user'
import useSWR from 'swr'

const VERIFY_DELAY = 5000

export const useDomainVerification = (domainId: DomainId) => {
  const { user } = useUser()
  const { fetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.get,
    DomainVerificationInterface
  >({ method: RequestMethodsEnum.get })
  const { data, mutate } = useSWR(
    () =>
      user &&
      !!domainId &&
      `/api/dashboard/customer/settings/mailing/domain-authentication/${domainId}`,
    fetcher,
  )
  const timerRef = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    if (!data) return

    if (data.activeRootPageStatus === DomainActiveRootStatusEnum.pending) {
      timerRef.current = setInterval(() => mutate(), VERIFY_DELAY)
    } else if (timerRef.current) {
      clearInterval(timerRef.current)
    }

    return () => clearInterval(timerRef.current || 0)
  }, [data, mutate])
  return { status: data?.activeRootPageStatus }
}
