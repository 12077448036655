export enum DateRangeEnum {
  ONE_WEEK = 'P1W',
  TWO_WEEK = 'P2W',
  THREE_WEEK = 'P3W',
  FOUR_WEEK = 'P4W',
  FIVE_WEEK = 'P5W',
  SIX_WEEK = 'P6W',
  SEVEN_WEEK = 'P7W',
  EIGHT_WEEK = 'P8W',
  NINE_WEEK = 'P9W',
  TEN_WEEK = 'P10W',
  ELEVEN_WEEK = 'P11W',
  TWELVE_WEEK = 'P12W',
  THIRTEEN_WEEK = 'P13W',
  FOURTEEN_WEEK = 'P14W',
  FIFTEEN_WEEK = 'P15W',
  SIXTEEN_WEEK = 'P16W',
  SEVENTEEN_WEEK = 'P17W',
  EIGHTEEN_WEEK = 'P18W',
  NINETEEN_WEEK = 'P19W',
  TWENTY_WEEK = 'P20W',
  TWENTY_ONE_WEEK = 'P21W',
  TWENTY_TWO_WEEK = 'P22W',
  TWENTY_THREE_WEEK = 'P23W',
  TWENTY_FOUR_WEEK = 'P24W',
  TWENTY_FIVE_WEEK = 'P25W',
  TWENTY_SIX_WEEK = 'P26W',
}
