import {
  MAILING_SETTINGS_SENDER_EMAILS_API,
  removeSenderEmailAddress,
  resendSenderEmailAddress,
} from 'modules/profile/mailing-settings/api/mailing-settings-sender-emails-api'
import CreateSenderEmailAddressModal from 'modules/profile/mailing-settings/components/create-sender-email-address-modal'
import ResendSenderEmailAddressModal from 'modules/profile/mailing-settings/components/resend-sender-email-address-modal'
import { SenderEmailStatusEnum } from 'modules/profile/mailing-settings/enums/sender-email-status-enum'
import { useSenderEmailAddresses } from 'modules/profile/mailing-settings/hooks/use-sender-email-addresses'
import {
  SenderEmailAddressId,
  SenderEmailAddressInterface,
} from 'modules/profile/mailing-settings/types/sender-email-address-interface'
import React from 'react'
import { Table } from 'shared/components/table/table'
import { ColumnDefinitionInterface } from 'shared/components/table/types/column-interface'
import { Tooltip } from 'shared/components/tooltip'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import { useUpdateData } from 'shared/hooks/use-update-data'
import { usePagination } from 'shared/route-query/hooks/use-pagination'
import { havePassed24Hours } from 'shared/utils/date-validators'
import WarningIcon from '../../../../shared/icons/warning-icon'

function SenderEmailsAddresses() {
  const { t } = useLocoTranslation()

  const { setPagination, pagination, setLimit, limit } = usePagination(true)
  const { emailAddresses, isValidating, mutate } = useSenderEmailAddresses({ pagination, limit })

  const updateData = useUpdateData({
    setPagination,
    api: MAILING_SETTINGS_SENDER_EMAILS_API,
    constantApi: [`${MAILING_SETTINGS_SENDER_EMAILS_API}/list`],
  })

  const mailingSettingsConfirmedEmailsColumns: ColumnDefinitionInterface<SenderEmailAddressInterface>[] =
    [
      {
        key: 'email',
        header: t('global.email_address'),
        widthPercent: 60,
        onRender: emailData => (
          <div className="truncate">
            <Tooltip mode="overflow" label={emailData.email}>
              <span className="truncate">{emailData.email}</span>
            </Tooltip>
          </div>
        ),
      },
      {
        key: 'status',
        header: t('global.status'),
        widthPercent: 20,
        onRender: emailData => (
          <div className="flex gap-2 items-center">
            {emailData.confirmedAt
              ? t(SenderEmailStatusEnum.Verified)
              : t(SenderEmailStatusEnum.Pending)}

            {!emailData.isValidAsSender && (
              <Tooltip
                mode="hover"
                label={t('dashboard.settings.mailing.email_cannot_be_sender_email')}
                className="mb-1"
              >
                <WarningIcon className="w-[18px] h-[18px] fill-yellow" />
              </Tooltip>
            )}
          </div>
        ),
      },
    ]

  const onRemoveEmail = async (id: SenderEmailAddressId) => {
    await removeSenderEmailAddress(id)
    await updateData(false, emailAddresses)
  }

  const onResendEmail = (senderEmail: SenderEmailAddressInterface) => async () => {
    const response = await resendSenderEmailAddress(senderEmail.id)
    await updateData(false, undefined, {
      mutationData: emailAddresses,
      onMutate: async () => {
        await mutate(data => {
          if (data) {
            return {
              items: data.items.map(item => {
                if (item.id === senderEmail.id) {
                  return response.data
                }
                return item
              }),
              hasMore: data.hasMore,
            }
          }
        }, false)
      },
    })
  }

  return emailAddresses && !isValidating ? (
    <div className="border border-gray/30 rounded-md">
      <Table
        actions={{
          remove: {
            onChange: emailAddress => onRemoveEmail(emailAddress.id),
          },
          custom: [
            {
              shouldRender: email => {
                return email.confirmedAt === null && havePassed24Hours(email.generatedAt)
              },
              name: 'resend',
              caption: t('dashboard.settings.mailing.sender_email.resend'),
              onActionRender: (
                opened: boolean,
                onClose: () => void,
                email: SenderEmailAddressInterface,
                key: string,
              ) => (
                <ResendSenderEmailAddressModal
                  opened={opened}
                  onClose={onClose}
                  key={key}
                  onResend={onResendEmail(email)}
                />
              ),
            },
          ],
        }}
        data={emailAddresses}
        columns={mailingSettingsConfirmedEmailsColumns}
        paginator={{ pagination, setPagination }}
        limiter={{ limit, setLimit }}
        caption={t('dashboard.settings.mailing.sender_email.title')}
        emptyListWithTitles
      />
      <CreateSenderEmailAddressModal
        updateSenderEmailsAddressesTable={() => updateData(true, emailAddresses)}
      />
    </div>
  ) : (
    <div className="flex flex-col w-full h-fit rounded-md overflow-x-hidden gap-5 bg-white">
      <div className="flex flex-col gap-7 lg:gap-10  animate-pulse ">
        <div className="flex flex-col lg:flex-row justify-between [&>*]:flex-1 lg:h-[104px] gap-7 lg:gap-10">
          <div className="bg-gray-600 rounded text-transparent my-2 py-1 lg:py-0">.</div>
        </div>
      </div>
    </div>
  )
}

export default SenderEmailsAddresses
