import { EmailListType } from 'modules/profile/mailing-settings/types/email-list-interface'
import { useEffect, useState } from 'react'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import useUser from 'shared/hooks/use-user'
import useSWR from 'swr'

export const useEmailList = (isReady?: boolean) => {
  const { user } = useUser()
  const [shouldFetch, setShouldFetch] = useState(false)

  useEffect(() => {
    if (isReady) {
      setShouldFetch(true)
    }
  }, [isReady])

  const { fetcher } = useApiWrapperWithErrorValidation<RequestMethodsEnum.get, EmailListType>({
    method: RequestMethodsEnum.get,
  })
  return useSWR(
    () => user && shouldFetch && '/api/dashboard/customer/mailing/sender-email/full-list',
    fetcher,
  )
}
