import React from 'react'

function EyeIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="#CBD5E1"
      {...props}
    >
      <g clipPath="url(#clip0_301_2)">
        <path d="M12 8.13763C9.86904 8.13763 8.13763 9.86904 8.13763 12C8.13763 14.131 9.86904 15.8624 12 15.8624C14.131 15.8624 15.8624 14.131 15.8624 12C15.8624 9.86904 14.131 8.13763 12 8.13763ZM11.707 10.8546C11.2275 10.8546 10.828 11.2542 10.828 11.7336H9.54939C9.57603 10.535 10.535 9.57604 11.707 9.57604V10.8546Z" />
        <path d="M23.7203 11.2009C22.4151 9.57605 17.7536 4.27527 12 4.27527C6.24639 4.27527 1.58491 9.57605 0.279689 11.2009C-0.0932297 11.6537 -0.0932297 12.3197 0.279689 12.7991C1.58491 14.424 6.24639 19.7248 12 19.7248C17.7536 19.7248 22.4151 14.424 23.7203 12.7991C24.0932 12.3463 24.0932 11.6804 23.7203 11.2009ZM12 17.5938C8.9101 17.5938 6.40622 15.0899 6.40622 12C6.40622 8.91012 8.9101 6.40623 12 6.40623C15.0899 6.40623 17.5938 8.91012 17.5938 12C17.5938 15.0899 15.0899 17.5938 12 17.5938Z" />
      </g>
      <defs>
        <clipPath id="clip0_301_2">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default EyeIcon
