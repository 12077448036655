import { getEmptyLocaleValues } from 'shared/utils/get-empty-locale-values'
import { MailingSettingsErrorsInterface } from '../types/mailing-settings-interface'

export const defaultErrors: MailingSettingsErrorsInterface = {
  emailMessageFooter: '',
  senderEmailAddress: '',
  senderEmailName: '',
  sendgridApiKey: '',
  testEmailAddress: '',
  unsubscriptionLinkText: getEmptyLocaleValues(),
  common: [],
}
