import { createDomain } from 'modules/profile/mailing-settings/api/mailing-settings-domain-api'
import DnsSettingsModal from 'modules/profile/mailing-settings/components/dns-settings-modal'
import {
  MailingSettingsDomainDnsSettingsInterface,
  MailingSettingsDomainErrorResponseContentInterface,
  MailingSettingsDomainErrorsInterface,
} from 'modules/profile/mailing-settings/types/mailing-settings-domain-interface'
import React, { useState } from 'react'
import toast from 'react-hot-toast'
import { PrimaryButton } from 'shared/components/button'
import FormInput from 'shared/components/form-input'
import Modal from 'shared/components/modal'
import Popover from 'shared/components/popover'
import { BadRequest } from 'shared/errors/bad-request'
import { InternalError } from 'shared/errors/internal-error'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import QuestionMarkIcon from 'shared/icons/question-mark-icon'

const defaultErrors: MailingSettingsDomainErrorsInterface = {
  domainName: '',
  common: [],
}
function SetupDomainAuthentication({ updateDomainTable }: { updateDomainTable: () => void }) {
  const { t } = useLocoTranslation()
  const [opened, setOpened] = useState(false)
  const [tempState, setTempState] = useState('')
  const [errors, setErrors] = useState(defaultErrors)
  const [isFetching, setIsFetching] = useState(false)
  const [dnsSettingsToSetup, setDnsSettingsToSetup] =
    useState<MailingSettingsDomainDnsSettingsInterface | null>(null)
  const [domainId, setDomainId] = useState<number | null>(null)

  const closeSetupDomainModal = () => {
    setOpened(false)
    setTempState('')
  }

  const handleConfirm = async () => {
    try {
      setIsFetching(true)
      setErrors(defaultErrors)
      const { data } = await createDomain({ domainName: tempState })
      await updateDomainTable()
      setIsFetching(false)
      toast.success(t('dashboard.settings.mailing.dns_settings_domain_authentication_added_title'))
      setDnsSettingsToSetup(data.dnsSettings)
      setDomainId(data.id)
      closeSetupDomainModal()
    } catch (e) {
      setIsFetching(false)
      if (e instanceof BadRequest) {
        const mailingSettingsDomainErrors =
          e.errors as unknown as MailingSettingsDomainErrorResponseContentInterface
        setErrors(prev => ({
          ...prev,
          domainName: mailingSettingsDomainErrors.fields.domainName?.join(''),
        }))

        if (e.errors.common) {
          const commonErrors = e.errors.common
          setErrors(prev => {
            return {
              ...prev,
              common: commonErrors,
            }
          })
        }
      } else if (e instanceof InternalError) {
        setErrors(t('core.error.internal_error_message'))
      }
      toast.error(t('global.error'))
    }
  }

  return (
    <>
      <div className="flex gap-3 items-center [&>svg]:fill-blue [&>svg]:main-transition-colors px-6 pt-6 pb-2">
        <div className="cursor-pointer text-blue" onClick={() => setOpened(true)}>
          {t('dashboard.settings.mailing.dns_settings_setup_title')}
        </div>
        <Popover
          label={
            <div
              className="[&>a]:text-blue"
              dangerouslySetInnerHTML={{
                __html: t('dashboard.settings.mailing.dns_settings_setup_popover_title'),
              }}
            />
          }
        >
          {show => (
            <QuestionMarkIcon
              className={`${
                show ? 'fill-blue' : 'fill-gray-100'
              } group-focus-visible:fill-blue w-[16px] h-[16px]`}
            />
          )}
        </Popover>
      </div>
      <Modal
        opened={opened}
        onClose={closeSetupDomainModal}
        title={t('dashboard.settings.mailing.setup_domain_modal_title')}
        className="max-w-2xl"
      >
        <form className="flex flex-col gap-6 lg:gap-7">
          <FormInput
            label={
              <div className={'flex gap-1.5 items-center'}>
                {t('dashboard.settings.mailing.setup_domain_modal_domain_name_title')}
                <Popover
                  label={t(
                    'dashboard.settings.mailing.setup_domain_modal_domain_name_popover_title',
                  )}
                  popoverClassName={'w-[300px] sm:w-[350px]'}
                >
                  {show => (
                    <QuestionMarkIcon
                      className={`${
                        show ? 'fill-blue' : 'fill-gray-100'
                      } group-focus-visible:fill-blue w-[16px] h-[16px]`}
                    />
                  )}
                </Popover>
              </div>
            }
            required
            value={tempState}
            onChange={e => setTempState(e.target.value)}
            error={errors.domainName}
          />
          <div className="flex justify-center gap-4">
            <PrimaryButton
              isFetching={isFetching}
              width="large"
              onClick={async e => {
                e.preventDefault()
                await handleConfirm()
              }}
              type="submit"
            >
              {t('global.save')}
            </PrimaryButton>
          </div>
          {errors.common && (
            <>
              {errors.common.map((error, index) => (
                <p className="text-sm text-red px-6" key={index}>
                  {error}
                </p>
              ))}
            </>
          )}
        </form>
      </Modal>
      {dnsSettingsToSetup && domainId && (
        <DnsSettingsModal
          domainId={domainId}
          dnsSettings={dnsSettingsToSetup}
          opened={true}
          onClose={() => {
            setDnsSettingsToSetup(null)
            setOpened(false)
          }}
        />
      )}
    </>
  )
}

export default SetupDomainAuthentication
