import {
  FieldErrorAndDescription,
  FieldErrorAndDescriptionProps,
} from 'shared/components/form/field-error-and-description'
import CheckCheckboxIcon from 'shared/icons/check-checkbox-icon'
import { twMerge } from 'tailwind-merge'

export interface CheckboxProps extends FieldErrorAndDescriptionProps {
  value: boolean
  onChange: (value: boolean) => void
  label?: string | JSX.Element
  disabled?: boolean
  isFetching?: boolean
  labelClassName?: string
  className?: string
}

export const Checkbox = ({
  error,
  description,
  value,
  onChange,
  label,
  disabled,
  isFetching,
  labelClassName,
  className,
}: CheckboxProps) => {
  return (
    <label
      className={twMerge(
        'flex gap-3 items-center w-fit group',
        disabled ? 'cursor-default' : 'cursor-pointer',
        className,
      )}
      aria-checked={value}
    >
      <div className="flex justify-center items-center relative">
        <input
          disabled={disabled}
          checked={value}
          className={`peer/checkbox main-transition-colors ${
            disabled
              ? 'bg-gray-600/40 border-darkgray/20 checked:bg-darkblue/60 checked:border-darkblue/75 cursor-default'
              : 'border-darkgray bg-white checked:border-darkblue checked:bg-darkblue'
          } ${
            isFetching && 'animate-pulse'
          } outline-none focus-visible:ring-2 ring-blue ring-offset-1 appearance-none cursor-pointer border h-[20px] w-[20px] rounded-md`}
          type="checkbox"
          onChange={e => onChange(e.target.checked)}
        />
        <CheckCheckboxIcon
          className={`absolute ${
            disabled
              ? value
                ? 'opacity-100 fill-white/70'
                : 'opacity-0'
              : 'opacity-100 fill-white'
          }`}
        />
      </div>
      {label && (
        <span
          className={`main-transition-colors ${
            value ? 'text-darkblue' : 'text-darkblue/50 '
          } leading-5 ${labelClassName || ''} text-left`}
        >
          {label}
        </span>
      )}
      <FieldErrorAndDescription description={description} error={error} />
    </label>
  )
}
