import CreateSenderEmailAddressModal from 'modules/profile/mailing-settings/components/create-sender-email-address-modal'
import React, { useRef } from 'react'
import FormInput, { InputProps } from 'shared/components/form-input'

interface FormSenderEmailInputProps extends InputProps {
  value: string
}

const FormSenderEmailInput = ({ value, label, error, ...rest }: FormSenderEmailInputProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null)
  return (
    <div ref={wrapperRef}>
      <FormInput value={value} label={label} error={error} {...rest} />
      <CreateSenderEmailAddressModal
        wrapperRef={wrapperRef}
        error={error}
        withoutButton
        value={value}
      />
    </div>
  )
}

export default FormSenderEmailInput
