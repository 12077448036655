import {
  EmailId,
  EmailInterface,
} from 'shared/components/dashboard-mail-editor/types/email-interface'
import httpClient from 'shared/utils/http-client'
import { MailingId } from '../../types/mailing-interface'

export const EMAIL_STATISTICS_API = '/api/dashboard/customer/mailing'

export const getEmailPreview = (id: EmailId) =>
  httpClient.get<EmailInterface>(`${EMAIL_STATISTICS_API}/${id}/preview`)

export const getEmailClickLinkStatisticsPath = (mailindId: MailingId) =>
  `${EMAIL_STATISTICS_API}/${mailindId}/click-link-statistics`
