import { Table } from 'dexie'
import { MailingId } from 'modules/mailing/types/mailing-interface'
import { UnsavedEmailsEnum } from 'shared/components/dashboard-mail-editor/enums/unsaved-emails-enum'

export interface EmailsInterface {
  id?: number
  mailing_id: MailingId | null
  type: UnsavedEmailsEnum | null
  body: string
}

export type EmailsTableType = {
  emails: Table<EmailsInterface>
}

export const emailsSchema = {
  emails: '++id, mailing_id, type, body',
}
